<template>
  <v-app>
    <router-view />
    <v-snackbar
      :value="snackbarOptions ? snackbarOptions.visible : false"
      :timeout="snackbarOptions ? snackbarOptions.timeout : 6000"
      @input="closeSnackbar"
    >
      {{ snackbarOptions ? snackbarOptions.message : '' }}
      <template #action>
        <v-btn
          color="primary"
          text
          @click="closeSnackbar"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    snackbarOptions() {
      return this.$store.getters.snackbarOptions
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('CLOSE_SNACKBAR')
    },
  },
}
</script>

<style lang="scss">
@import './styles/variables.scss';
</style>

<style>
html,
body {
  height: 100% !important;
  width: 100% !important;
  min-height: 100% !important;
}
.v-application,
.v-application--wrap {
  height: 100% !important;
  min-height: 100% !important;
}

/* force gray-100 background for light application */
.theme--light.v-application {
  background: #f7fafc !important;
}
/* force gray-100 background for container */
.container {
  background: #f7fafc !important;
}

/* force white background for dialog */
.v-application .v-dialog {
  background-color: white !important;
}

/* workaround for snackbar not visible (ref: https://github.com/vuetifyjs/vuetify/issues/11781) */
.v-snack.v-snack--bottom {
  top: initial;
}

/* override: remove click effect on click finish */
button:hover:before,
button:focus:before {
  background-color: white !important;
}

/* override: input box shadows */
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

/* prevent scrolling background when nav drawer is open */
.overflow-y-hidden {
  overflow-y: hidden !important;
}

/* workaround for snackbar not visible (ref: https://github.com/vuetifyjs/vuetify/issues/11781) */
.v-snack.v-snack--bottom {
  top: initial;
}

/* custom link styling  */
.internal-link {
  text-decoration: underline !important;
  text-decoration-color: #18a5a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
