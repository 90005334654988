export default {
  isloggedIn(state) {
    if (state.token === null || state.user.id === null) return false
    return true
  },
  userPhoneNumber(state) {
    return state.user.phoneNumber
  },
  userName(state) {
    return state.user.name
  },
  user(state) {
    return {
      id: state.user.id,
      name: state.user.name,
      phoneNumber: state.user.phoneNumber,
      region: state.user.region,
      blocked: state.user.blocked,
    }
  },
  currentSalesUser(state) {
    return state.currentSalesUser
  },
  snackbarOptions(state) {
    return state.snackbar
  },
}
