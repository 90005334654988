import initialState from './state.js'

export default {
  /* general mutations */
  SHOW_SNACKBAR(state, data) {
    state.snackbar = {
      visible: true,
      timeout: data.timeout || 6000,
      message: data.message,
    }
  },
  CLOSE_SNACKBAR(state) {
    state.snackbar.visible = false
  },

  /* pre-login mutations */

  SET_USER_PHONE_NUMBER(state, data) {
    state.user.phoneNumber = data
  },

  /* login mutations */
  SET_USER_SESSION(state, data) {
    // set token
    if (data.token) state.token = data.token
    // set user data
    state.user = { ...state.user, ...data.user }
  },

  /* logout mutations */

  CLEAR_STATE(state) {
    Object.assign(state, initialState())
  },
}
