
<v-app>
  <router-view />
  <v-snackbar
    :value="snackbarOptions ? snackbarOptions.visible : false"
    :timeout="snackbarOptions ? snackbarOptions.timeout : 6000"
    @input="closeSnackbar"
  >
    {{ snackbarOptions ? snackbarOptions.message : '' }}
    <template #action>
      <v-btn
        color="primary"
        text
        @click="closeSnackbar"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</v-app>
