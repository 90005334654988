import store from '@/store'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// define multiple guards helper
const isUndefined = (value) => value === undefined
const evaluateGuards = (guards, to, from, next) => {
  const guardsLeft = guards.slice(0)
  const nextGuard = guardsLeft.shift()

  if (isUndefined(nextGuard)) {
    next()
    return
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next)
      return
    }

    next(nextArg)
  })
}
const multipleGuards = (guards) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards')
  }

  return (to, from, next) => {
    return evaluateGuards(guards, to, from, next)
  }
}

// atomic guard rules
const disallowIfLogin = (_to, _from, next) => {
  if (store.getters.isloggedIn) {
    next({ path: '/app' })
  } else {
    next()
  }
}
const requirePhoneNumber = (_to, _from, next) => {
  if (!store.getters.userPhoneNumber) {
    next({ path: '/' })
  } else {
    next()
  }
}
const requireLogin = (_to, _from, next) => {
  if (!store.getters.isloggedIn) {
    next({ path: '/' })
  } else {
    next()
  }
}

// create new router
export default new Router({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'phone',
      component: () => import('@/views/Phone.vue'),
      beforeEnter: disallowIfLogin,
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: multipleGuards([requirePhoneNumber, disallowIfLogin]),
    },
    {
      path: '/app',
      redirect: '/app/sales-logs/create',
    },
    {
      path: '/app',
      component: () => import('@/views/MainApp.vue'),
      beforeEnter: multipleGuards([requireLogin]),
      children: [
        {
          path: 'sales-logs',
          component: () => import('@/views/SalesLogMain.vue'),
          children: [
            {
              path: 'create',
              name: 'sales-log-create',
              component: () => import('@/views/SalesLogCreate.vue'),
              meta: { title: 'Sales logs' },
            },
            {
              path: 'view',
              name: 'sales-log-view',
              component: () => import('@/views/SalesLogView.vue'),
              meta: { title: 'Sales logs' },
            },
          ],
        },
        {
          path: 'available-locations',
          name: 'available-locations',
          component: () => import('@/views/AvailableLocations.vue'),
          meta: { title: 'Available locations' },
        },
        {
          path: '/session-expired',
          name: 'session-expired',
          component: () => import('@/views/SessionExpired.vue'),
          meta: { title: 'Session expired' },
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  },
})
