export const CHECK_SALES_USER_QUERY = `
  query ($phoneNumber: String!) {
    checkSalesUser (phoneNumber: $phoneNumber) {
      exists
      blocked
    }
  }
`

export const SEND_OTP_SMS_MUTATION = `
  mutation ($input: SendOtpSmsInput!) {
    sendOtpSms (input: $input) {
      type
    }
  }
`

export const RESEND_OTP_SMS_MUTATION = `
  mutation ($input: ResendOtpSmsInput!) {
    resendOtpSms (input: $input) {
      type
    }
  }
`

export const LOGIN_OTP_MUTATION = `
  mutation ($input: LoginSalesUserOtpInput!) {
    loginSalesUserOtp(input: $input) {
      token
      user {
        id
        name
        phoneNumber
        region
        blocked
      }
    }
  }
`

export const USER_PROFILE_QUERY = `
  query {
    currentSalesUser {
      id
      name
      phoneNumber
      region
      blocked
    }
  }
`

export const SALES_BUSINESSES_QUERY = `
  query ($region: String!) {
    salesBusinesses (region: $region) {
      name
    }
  }
`

export const SALES_LOG_PHOTO_URL_MUTATION = `
  mutation {
    createSalesLogPhotoUrl {
      fileName
      url
    }
  }
`

export const CREATE_SALES_LOG_MUTATION = `
  mutation ($input: CreateSalesLogInput!) {
    createSalesLog(input: $input) {
      id
    }
  }
`

export const SALES_LOGS_QUERY = `
  query ($after: Int, $first: Int, $before: Int, $last: Int) {
    currentSalesUser {
      salesLogs(after: $after, first: $first, before: $before, last: $last) {
        edges {
          node {
            id
            latitude
            longitude
            photos
            type
            businessName
            pocName
            pocPhoneNumber
            pocEmail
            comments
            status
            potential
            driveDistance
            createdAt
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
`
